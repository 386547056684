/** @jsx jsx */
import { useState } from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { jsx, css } from "@emotion/core"
import tw from "tailwind.macro"
import { useSpring, animated } from "react-spring"
import config from "../config"

const HeroDiv = styled.div`
  ${tw`
      relative
    `}
`
const BookCardDiv = styled(animated.a)`
  ${tw`
      relative mx-6 w-40 flex flex-col justify-center align-middle 
    `}
`
// const AnimatedHeader = styled(animated.h1)`
const BookContainer = styled.div`
  ${tw`
absolute flex flex-row flex-no-wrap right-0 bottom-0 mb-4 md:mb-12 lg:mb-0 top-auto lg:top-0 justify-center lg:justify-end align-bottom lg:align-middle mr-0 lg:mr-16
`}
`

const BookCard = ({ image, buylink }) => {
  const [isHover, setIsHover] = useState(false)
  const animation = useSpring({
    transform: isHover ? `scale(1.05)` : `scale(1)`,
  })

  return (
    <BookCardDiv
      style={animation}
      href={buylink}
      target="blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Img className="shadow-2xl" fluid={image} />
      <span className="block center mt-4 w-full text-center text-white hover:text-green-700 ">
        Buy now{" "}
      </span>
    </BookCardDiv>
  )
}

const Hero = ({ data }) => {
  return (
    <HeroDiv
      css={css`
        height: 500px;
        @media (min-width: 520px) and (max-width: 1024px) {
          height: 800px;
        }
      `}
    >
      <Img
        className="hidden lg:block"
        css={{
          height: "100%",
        }}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
      <Img
        className="hidden md:block lg:hidden"
        css={{
          height: "100%",
        }}
        fluid={data.placeholderTabletImage.childImageSharp.fluid}
      />
      <Img
        className="md:hidden lg:hidden"
        css={{
          height: "100%",
        }}
        fluid={data.placeholderMobileImage.childImageSharp.fluid}
      />
      <BookContainer className="w-full lg:w-auto lg:pt-0">
        <BookCard
          image={data.spanishSunThumb.childImageSharp.fluid}
          buylink={config.buylinks.spanishLink}
        />
        <BookCard
          image={data.italianAffairThumb.childImageSharp.fluid}
          buylink={config.buylinks.italianLink}
        />
        <BookCard
          image={data.parisSecretThumb.childImageSharp.fluid}
          buylink={config.buylinks.parisLink}
        />
      </BookContainer>
    </HeroDiv>
  )
}

export default Hero
