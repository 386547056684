import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Loyout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import Logos from "../components/logos"
import About from "../components/about"
import Books from "../components/books"
import AboutBurntNorton from "../components/aboutBurntNorton"
import Reviews from "../components/Reviews"
import config from "../config"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "caroline.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderTabletImage: file(
        relativePath: { eq: "caroline_tablet.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderMobileImage: file(
        relativePath: { eq: "caroline_mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      italianAffair: file(
        relativePath: {
          eq: "caroline-montague_homepage-link-an-italian-affair.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      parisSecret: file(
        relativePath: {
          eq: "caroline-montague_homepage-link-a-paris-secret.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spanishSunThumb: file(
        relativePath: {
          eq: "caroline-montague_shadows-over-the-spanish-sun.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 325, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spanishSun: file(
        relativePath: {
          eq: "caroline-montague_shadows-over-the-spanish-sun-background.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 325, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      italianAffairThumb: file(
        relativePath: {
          eq: "caroline-montague_book-cover-an-italian-affair.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 325, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      parisSecretThumb: file(
        relativePath: { eq: "caroline-montague_book-cover-a-paris-secret.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 325, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Caroline Montague - Home"
        description="From early childhood a poem, executed by my own hand was a compulsory requirement for my father’s birthday"
      />
      <Hero data={data} />
      <Logos />
      <About />
      <Books data={data} />
      <AboutBurntNorton />
      <Reviews reviews={config.reviews} />
    </Layout>
  )
}

export default IndexPage
