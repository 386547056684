import React from "react"
import { Link as ScrollLink } from "react-scroll"

const About = () => {
  return (
    <div id="about" className="flex flex-col my:8 md:my-16 px-8 md:px-4">
      <h2 className="font-heading text-2xl tracking-widest uppercase text-center text-heading">
        About
      </h2>
      <div className="mt-10 mb-0 mx-auto max-w-3xl">
        <p className="text-center mb-4">
          From early childhood a poem, executed by my own hand was a compulsory
          requirement for my father’s birthday. To the reluctant five year old
          this was considered a trial, but by the age of seven writing poetry
          had become my passion. At ten I won my first national poetry
          competition and from that moment I dreamt of being a writer.
        </p>
        <p className="text-center mb-4">
          This particular dream took rather longer than I had hoped because
          reading law, marriage at nineteen, children, a career as an interior
          designer – something always got in the way. When I moved with my
          second husband, three children and four step children to Burnt Norton
          twenty years ago, and I happened upon the empty pools made famous by
          TS Eliot in the first of his Four Quartets, I knew that one day the
          dream would become a reality.
        </p>
        <p className="text-center">
          In 2018 after first writing a historical novel set at Burnt Norton I
          changed agents to William Morris Endeavour and it was here that I felt
          truly at home. Within a fairly short time, Matilda Forbes Watson had
          procured a two book deal with Orion for ‘An Italian Affair’ and ‘A
          Paris Secret’. She recently procured a further two book deal for a
          novel set in Spain during the Spanish Civil War and a novel set in
          Greece.
        </p>
        <ScrollLink
          activeClass="active"
          to="burnt-norton"
          spy={true}
          smooth={true}
          duration={500}
        >
          <span className="inline-block mt-8 text-center w-full cursor-pointer">
            Learn more about Burnt Norton
          </span>
        </ScrollLink>
      </div>
    </div>
  )
}

export default About
