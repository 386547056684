import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

import waterstones from "./../images/icons/waterstones.svg"
import theTimes from "./../images/icons/the_times.svg"
import amazon from "./../images/icons/amazon.svg"
import goodreads from "./../images/icons/goodreads.svg"

const logos = [
  {
    id: 1,
    logo: waterstones,
  },
  { id: 2, logo: theTimes },
  { id: 3, logo: amazon },
  { id: 4, logo: goodreads },
]
const LogoContainer = styled.div`
  ${tw`
    my-10 flex-col md:flex-row flex
    `}
`

const LogoItem = styled.div`
  ${tw`
  w-full mb-6 md:mb-0 md:w-1/4 border-gray-100 border-separate border-solid border-gray-100 flex justify-center align-middle
  `}
`

const Logos = () => {
  return (
    <LogoContainer className="justify-center align-middle w-full lg:w-10/12 mx-auto ">
      {logos.map(logo => {
        const noBorderRight =
          logo.id === 4 ? `border-0 md:border-0` : `border-0 md:border-r`
        return (
          <LogoItem key={logo.id} className={noBorderRight}>
            <img className="h-4 md:h-5 lg:h-6" src={logo.logo} alt="" />
          </LogoItem>
        )
      })}
    </LogoContainer>
  )
}

export default Logos
