/** @jsx jsx */
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { jsx } from "@emotion/core"
import tw from "tailwind.macro"
import { useStaticQuery, graphql } from "gatsby"

const AboutBurntNorton = () => {
  const data = useStaticQuery(graphql`
    query {
      burntNorton: file(relativePath: { eq: "burnt_norton.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      name="burnt-norton"
      id="burnt-norton"
      className="w-full bg-gray-200 py-12 px-8 md:px-12 flex flex-col md:flex-row justify-between align-middle"
    >
      <div className="w-full md:w-1/2 md:mr-5">
        <Img
          className="object-cover z-50 w-full"
          fluid={data.burntNorton.childImageSharp.fluid}
        />
      </div>
      <div className="w-full md:w-1/2 md:ml-5 mt-5 md:mt-0">
        <h3 className="uppercase mt-4 mb-6 tracking-widest text-2xl font-heading text-heading">
          Burnt Norton
        </h3>
        <p className="mb-6">
          Shortly after moving into Burnt Norton twenty years ago, I learnt the
          tragic story Sir William Keyt, master of Norton House in the 18th
          Century. Despite his wealth, his social position at the head of a
          loving family, he began a descent into a spiral of misery and excess,
          squandering the fortune amassed by his ancestors and destroying the
          family name.
        </p>
        <p>
          This became the subject and title of my first novel, Burnt Norton. We
          still live in this evocative and wonderful house, that inspired TS
          Eliot to write the first of his Four Quartets. Everyone who ventures
          beyond the while gates into the pool garden can not help but be
          captivated by the mysterious and haunting atmosphere still remaining
          to this day.
        </p>
      </div>
    </div>
  )
}

export default AboutBurntNorton
